import {Static, Type} from '@sinclair/typebox';
import {
  TFirebaseCompEntry,
  TFirebaseCompSummary,
} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {
  TAiPrompt,
  TCompLogo,
  TCompQuestion,
} from '../../../interfaces/firestore/FirestoreInterfaces';

export enum ClientStatus {
  RESULTED = 0,
  IN_PLAY = 1,
  PRE_START = 2,
  OPEN = 3,
  VERIFIED = 4,
  SUSPENDED = 5,
}

export enum QuestionResolutionStatus {
  INCORRECT = -1,
  NO_ANSWER = 0,
  CORRECT = 1,
  INCORRECT_OPEN = 2,
}

export interface ICompSummaryAndEntry {
  summary: TFirebaseCompSummary;
  vendor: IVendorDetails;
  entry: TFirebaseCompEntry | null;
  outstandingQuestions: number;
}

export interface IVendorDetails {
  vendor: string;
  media: TCompLogo;
  primaryColor: string;
  name: string;
  strapline: string;
  isMiniLeague: boolean;
  tags: string[];
}

export const IQuestionAndPrompts = Type.Object({
  question: TCompQuestion,
  captionPrompt: Type.Union([TAiPrompt, Type.Null()]),
  questionPrompt: Type.Union([TAiPrompt, Type.Null()]),
  resolved: Type.Optional(
    Type.Union([Type.Number(), Type.Array(Type.Number()), Type.Null()]),
  ),
});
export type IQuestionAndPrompts = Static<typeof IQuestionAndPrompts>;
