import {TCompResolvedQuestion} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';

export const getResolutionForQuestion = (
  questionId: string,
  resolvedQuestions: TCompResolvedQuestion[],
  resolutions: TCompResolvedQuestion[],
) => {
  const resolution = [...resolvedQuestions, ...resolutions].find(
    (r) => r.id === questionId,
  );
  if (resolution !== undefined) {
    return resolution.resolved;
  } else {
    return undefined;
  }
};
