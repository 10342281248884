import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMatch, useNavigate} from 'react-router';
import {animated, useSpring} from 'react-spring';
import {Observable} from 'rxjs';

import {
  AppNavigation,
  GameScreenNames,
} from '@chancer/common/lib/app/AppMessage';
import {
  ClientStatus,
  ICompSummaryAndEntry,
  IVendorDetails,
  QuestionResolutionStatus,
} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  AppPlatform,
  EnterCompStatus,
  IAnalyticsPayload,
} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseChallengeGroup,
  TFirebaseChatMessage,
  TFirebaseCompCounts,
  TFirebaseCompEntry,
  TFirebaseCompSummary,
  TFirebaseGroupCounts,
  TFirebaseLeaderboard,
  TFirebaseQuestion,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  CompQuestionType,
  QuestionStatus,
  TLeader,
  TLeaderboardSummaryAndLeaders,
  TMediaEntry,
  TOpenGraph,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {IOverlay} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import {applyWindowsScrollStyle} from '@chancer/common/lib/utils/ComponentUtils';
import {getLeaderboardSummaryAndLeadersByIdStream} from '@chancer/common/lib/utils/LeaderboardHttpUtils';
import {getOpenGraphStream} from '@chancer/common/lib/utils/OpenGraphHttpUtils';
import {getUserByIdStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {PrimaryButton} from '@chancer/components/lib/Button/PrimaryButton';
import {UpdateRequired} from '@chancer/components/lib/Chat/UpdateRequired';
import {PrizeCard} from '@chancer/components/lib/Leaderboard/PrizeCard';
import {SimpleHeader} from '@chancer/components/lib/Leaderboard/SimpleHeader';
import {Tabs} from '@chancer/components/lib/Tabs/Tabs';

import {IAction} from '../../actions/Actions';
import {trackSelectContent} from '../../actions/analytics/AnalyticsActions';
import {
  sendCurrentScreen,
  sendEditProfile,
  sendNavigate,
} from '../../actions/messages/MessageActions';
import {addOverlay} from '../../actions/overlay/OverlayActions';
import {
  getClientCompetitionStatus,
  getCompetitionChat,
  getCompetitionChatUnreadCount,
  getCompetitionCounts,
  getCompetitionSummary,
  getEnterCompetitionStatus,
  getQuestions,
  getUsersCompetitionEntry,
} from '../../selectors/competitions/CompetitionSelectors';
import {getConfig} from '../../selectors/config/ConfigSelectors';
import {
  getIsChallengesEnabled,
  getIsCompetitionChatEnabled,
} from '../../selectors/features/FeatureFlagSelectors';
import {
  getLeaderboard,
  getLeaderboardConfig,
  getUsersCompetitionResults,
  getUsersLeaderboardEntry,
} from '../../selectors/leaderboard/AppLeaderboardSelectors';
import {
  COMP_DETAILS_INDEX,
  HOME_PAGE_INDEX,
} from '../../selectors/startup/StartupSelectors';
import {
  getLatestUserInfo,
  getUserId,
  getUserImageUrl,
  getUserIsFollowingAsMap,
} from '../../selectors/user/AppUserSelectors';
import {IntersectionRoot} from '../Core/Intersection/IntersectionRoot';
import {MediaView} from '../Core/Media/MediaView';
import {PlayersAnswers} from './Answers/PlayersAnswers';
import IComponentProps from './CompetitionDetailsScreenProps';
import {LeaderboardDetails} from './Details/LeaderboardDetails';
import {Latest} from './Latest/Latest';
import {useDetailsTabs} from './LeaderboardHooks';
import {UsersEntrantItem} from './List/UsersEntrantItem';

import {
  getChallengeDetailsOverlay,
  getCompareOverlay,
  getNativeAppChallengeOverlay,
  getNativeAppMissedQuestionOverlay,
  getPlayerProfileOverlay,
} from '@chancer/common/lib/interfaces/overlay/OverlayCreator';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {createCompetitionVendor} from '@chancer/common/lib/utils/CompetitionUtils';
import {assertType} from '@chancer/common/lib/utils/TypeUtils';
import {ChallengeCard} from '@chancer/components/lib/Chat/ChallengeCard';
import {EditAnswersCard} from '@chancer/components/lib/Chat/EditAnswersCard';
import {SummaryCard} from '@chancer/components/lib/Chat/SummaryCard';
import {ILeaderboardSummaryProps} from '@chancer/components/lib/Leaderboard/LeaderboardSummary';
import {COLOR_YELLOW} from '@chancer/components/lib/Styles/DesignSystem-chancer';
import {
  getChallengeCounts,
  getChallengeReadCounts,
  getChallenges,
} from '../../selectors/groups/GroupsSelectors';
import {getVipsInLeaderboard} from '../../selectors/vips/VipSelectors';
import {Chat} from './Chat/Chat';
import './CompetitionDetailsScreen.scss';

interface IStateProps {
  gameUrl: string;
  cdnUrl: string;
  leaderboardConfig: TFirebaseLeaderboard;
  questions: TFirebaseQuestion[] | null;
  competitionSummary: TFirebaseCompSummary | null;
  competitionData: TFirebaseCompCounts;
  competitionStatus: ClientStatus;
  competitionChat: TFirebaseChatMessage[];
  vipsInLeaderboard: TFirebaseUser[];
  unreadChatCount: number;
  leaderboard: TLeader[];
  currentUserId: string | null;
  currentUser: TFirebaseUser;
  currentUserImageUrl: string | null;
  currentUserEntry: TFirebaseCompEntry | null;
  currentUserLeaderboardEntry: TLeader | null;
  userFollowing: {[key: string]: boolean};
  usersCompetitionResults: QuestionResolutionStatus[];
  entryStatus: EnterCompStatus;
  isChallengesEnabled: boolean;
  isCompetitionChatEnabled: boolean;
  challenges: TFirebaseChallengeGroup[];
  challengeCounts: {[challengeId: string]: TFirebaseGroupCounts} | null;
  challengeReadCounts: {[challengeId: string]: number} | null;
  getUserByIdStream: (
    gameUrl: string,
  ) => (userId: string) => Observable<TFirebaseUser>;
  getLeaderboardSummaryAndLeadersByIdStream: (
    gameUrl: string,
  ) => (
    leaderboardId: string,
    invalidationKey?: string,
    length?: number,
  ) => Observable<TLeaderboardSummaryAndLeaders>;
  getOpenGraphStream: (
    gameUrl: string,
  ) => (url: string) => Observable<TOpenGraph>;
}

interface IDispatchProps {
  onScreenView: (screenName: string) => void;
  onNavigate: (nav: AppNavigation, path?: string) => void;
  onAddOverlay: (overlay: IOverlay) => void;
  onAnalytics: (action: IAction<IAnalyticsPayload>) => void;
  onEditProfile: () => void;
}

export const CompetitionDetailsScreen: React.FC<IComponentProps> = (props) => {
  const dispatch = useDispatch();

  const onScreenViewCallback = useCallback(
    (screenName: string) => dispatch(sendCurrentScreen(screenName)),
    [dispatch],
  );
  return (
    <CompetitionDetailsScreenView
      isChallengesEnabled={useSelector(getIsChallengesEnabled)}
      gameUrl={useSelector(getConfig).gameUrl}
      cdnUrl={useSelector(getConfig).cdnUrl}
      leaderboardConfig={useSelector(getLeaderboardConfig)}
      competitionSummary={useSelector(getCompetitionSummary)}
      competitionData={useSelector(getCompetitionCounts)}
      competitionStatus={useSelector(getClientCompetitionStatus)}
      competitionChat={useSelector(getCompetitionChat)}
      unreadChatCount={useSelector(getCompetitionChatUnreadCount)}
      vipsInLeaderboard={useSelector(getVipsInLeaderboard)}
      questions={useSelector(getQuestions)}
      leaderboard={useSelector(getLeaderboard)}
      currentUserId={useSelector(getUserId)}
      currentUser={useSelector(getLatestUserInfo)}
      currentUserImageUrl={useSelector(getUserImageUrl)}
      currentUserEntry={useSelector(getUsersCompetitionEntry)}
      currentUserLeaderboardEntry={useSelector(getUsersLeaderboardEntry)}
      userFollowing={useSelector(getUserIsFollowingAsMap)}
      usersCompetitionResults={useSelector(getUsersCompetitionResults)}
      entryStatus={useSelector(getEnterCompetitionStatus)}
      isCompetitionChatEnabled={useSelector(getIsCompetitionChatEnabled)}
      challenges={useSelector(getChallenges)}
      challengeCounts={useSelector(getChallengeCounts)}
      challengeReadCounts={useSelector(getChallengeReadCounts)}
      onScreenView={onScreenViewCallback}
      onNavigate={(nav, path) => dispatch(sendNavigate(nav, path))}
      onAddOverlay={(overlay: IOverlay) => dispatch(addOverlay(overlay))}
      onAnalytics={(action) => dispatch(action)}
      onEditProfile={() => dispatch(sendEditProfile())}
      getUserByIdStream={getUserByIdStream}
      getLeaderboardSummaryAndLeadersByIdStream={
        getLeaderboardSummaryAndLeadersByIdStream
      }
      getOpenGraphStream={getOpenGraphStream}
      {...props}
    />
  );
};

export enum CompetitionDetailsSubView {
  CHAT = 'chat',
  LATEST = 'latest',
  ANSWERS = 'answers',
  PRIZES = 'prizes',
  PROFILE = 'profile',
  COMPARE = 'compare',
  MORE_INFO = 'more_info',
}

export const CompetitionDetailsScreenView: React.FC<
  IComponentProps & IStateProps & IDispatchProps
> = (props) => {
  const history = useNavigate();
  const isActive = props.currentIndex === props.pageIndex;
  const match = useMatch('/:id/:subview');
  const match2 = useMatch('/:id');

  const subview: string | undefined =
    isActive && match && match.params.id === COMP_DETAILS_INDEX.toString()
      ? match.params.subview
      : isActive && match2 && match2.params.id === COMP_DETAILS_INDEX.toString()
        ? CompetitionDetailsSubView.LATEST
        : CompetitionDetailsSubView.LATEST;

  const [selectedMoreInfo, setSelectedMoreInfo] = useState<string | null>(null);

  const scrollingContainerRef = useRef<HTMLDivElement | null>(null);
  const usersEntrantItemRef = useRef<HTMLDivElement | null>(null);

  const {
    appPlatform,
    challenges,
    challengeCounts,
    challengeReadCounts,
    competitionSummary,
    competitionData,
    competitionChat,
    unreadChatCount,
    questions,
    pageIndex,
    gameUrl,
    leaderboard,
    currentUserId,
    currentUserEntry,
    safeAreaTopPadding,
    userHasEntered,
    onScreenView,
    onNavigate,
    onAnalytics,
    onEditProfile,
    onAddOverlay,
    getUserByIdStream,
    getLeaderboardSummaryAndLeadersByIdStream,
    getOpenGraphStream,
    onChangeAnswer,
  } = props;
  const compId = props.comp.id;
  const vendor = createCompetitionVendor(
    props.competitionSummary ?? props.comp,
  );
  const colorConfig = getColorConfiguration(props.comp.primaryColor);

  const challengesWithUnreads = useMemo(() => {
    return (
      challenges?.map((challenge) => ({
        challenge,
        badge: challengeCounts?.[challenge.id]?.messageCount
          ? challengeCounts[challenge.id].messageCount -
            (challengeReadCounts?.[challenge.id] ?? 0)
          : 0,
      })) ?? null
    );
  }, [challengeCounts, challengeReadCounts, challenges]);

  const _currentUsersLeaderboardIndex = useMemo(
    () => leaderboard.findIndex((l) => l.u === currentUserId),
    [leaderboard, currentUserId],
  );

  const _getUserByIdCallback = useMemo(
    () => getUserByIdStream(gameUrl),
    [gameUrl, getUserByIdStream],
  );

  const _getLeaderboardSummaryAndLeadersByIdCallback = useMemo(
    () => getLeaderboardSummaryAndLeadersByIdStream(gameUrl),
    [gameUrl, getLeaderboardSummaryAndLeadersByIdStream],
  );

  const _getOpenGraphCallback = useMemo(
    () => getOpenGraphStream(gameUrl),
    [gameUrl, getOpenGraphStream],
  );

  const _getHostname = useCallback((u: string) => {
    try {
      return new URL(u).hostname;
    } catch (e) {}

    return undefined;
  }, []);

  const [scrollProps, setScrollProps] = useSpring(() => ({
    reset: true,
    immediate: false,
    scroll: 0,
    from: {scroll: 0},
  }));

  useEffect(() => {
    if (!isActive) {
      return;
    }
    switch (subview) {
      case CompetitionDetailsSubView.CHAT:
        onScreenView(GameScreenNames.CHAT);
        return;
      case CompetitionDetailsSubView.LATEST:
        onScreenView(GameScreenNames.LATEST);
        return;
      case CompetitionDetailsSubView.ANSWERS:
        onScreenView(GameScreenNames.ANSWERS);
        return;
      case CompetitionDetailsSubView.PRIZES:
        onScreenView(GameScreenNames.PRIZES);
        return;
      default:
        return;
    }
  }, [isActive, subview, onScreenView]);

  const tabsList = useDetailsTabs(unreadChatCount);

  const _onTab = useCallback(
    (tabIndex: number, isClick: boolean = true) => {
      const tab = tabsList[tabIndex];

      if (tab.id === 'chat') {
        if (isClick) {
          onAnalytics(trackSelectContent('leaderboard_screen_chat-tab'));
        }
        history(
          {
            pathname: `/${pageIndex}/${CompetitionDetailsSubView.CHAT}`,
            search: window.location.search,
          },
          {replace: true},
        );
      } else if (tab.id === 'latest') {
        if (isClick) {
          onAnalytics(trackSelectContent('leaderboard_screen_leaderboard-tab'));
        }
        history(
          {
            pathname: `/${pageIndex}/${CompetitionDetailsSubView.LATEST}`,
            search: window.location.search,
          },
          {replace: true},
        );
      } else if (tab.id === 'answers') {
        if (isClick) {
          onAnalytics(trackSelectContent('leaderboard_screen_answers-tab'));
        }
        history(
          {
            pathname: `/${pageIndex}/${CompetitionDetailsSubView.ANSWERS}`,
            search: window.location.search,
          },
          {replace: true},
        );
      } else {
        if (isClick) {
          onAnalytics(trackSelectContent('leaderboard_screen_prizes-tab'));
        }
        history(
          {
            pathname: `/${pageIndex}/${CompetitionDetailsSubView.PRIZES}`,
            search: window.location.search,
          },
          {replace: true},
        );
      }
    },
    [tabsList, pageIndex, history, onAnalytics],
  );

  const _onGoToCompetitionScreen = useCallback(() => {
    history(
      {
        pathname: `/${HOME_PAGE_INDEX}`,
        search: window.location.search,
      },
      {replace: true},
    );
  }, [history]);

  const _onUpdateRequiredPressed = useCallback(() => {
    const url =
      props.appPlatform === AppPlatform.ANDROID
        ? 'https://play.google.com/store/apps/details?id=com.chancer&referrer=utm_source%3Dchancer%26utm_medium%3Dupdate%20overlay%26utm_campaign%3Dapp%20update%20overlay'
        : 'https://click.google-analytics.com/redirect?tid=UA-217970831-1&url=https%3A%2F%2Fitunes.apple.com%2Fnz%2Fapp%2Fchancer%2Fid1515390821&aid=com.chancer&cs=chancer&cm=app%20update%20overlay';
    window.open(url, '_blank');
  }, [props.appPlatform]);

  const _onEditProfile = useCallback(
    (user: TFirebaseUser) => {
      if (user.id === currentUserId) {
        onAnalytics(trackSelectContent('leaderboard_screen_edit-profile'));
        onEditProfile();
      }
    },
    [currentUserId, onAnalytics, onEditProfile],
  );

  const _onMultiGameLeaderboardSelected = useCallback(
    (leaderboardId: string) => {
      onAnalytics(
        trackSelectContent('leaderboard_screen_multigame-leaderboard'),
      );
      onNavigate(AppNavigation.LEADERBOARD, leaderboardId);
    },
    [onAnalytics, onNavigate],
  );

  const _onPlayNewQuestionSelected = useCallback(() => {
    onAnalytics(trackSelectContent('leaderboard_screen_cross-sell-comp-tile'));
    onNavigate(AppNavigation.RELOAD);
  }, [onAnalytics, onNavigate]);

  const _onMoreInfoSelected = useCallback(
    (info: string) => {
      onAnalytics(trackSelectContent('leaderboard_screen_more-info'));
      setSelectedMoreInfo(info);
      history({
        pathname: `/${pageIndex}/${CompetitionDetailsSubView.MORE_INFO}`,
        search: window.location.search,
      });
    },
    [history, pageIndex, onAnalytics],
  );

  const _onUserSelected = useCallback(
    (user: TFirebaseUser) => {
      if (user.id !== '') {
        onAnalytics(trackSelectContent('leaderboard_screen_view-profile'));
        onAddOverlay(
          getPlayerProfileOverlay(
            user,
            vendor.vendor,
            compId,
            questions || undefined,
            competitionData,
          ),
        );
      }
    },
    [vendor, compId, competitionData, questions, onAddOverlay, onAnalytics],
  );

  const _onVendorSelected = useCallback(
    (vendor: IVendorDetails) => {
      onAnalytics(trackSelectContent('leaderboard_screen_view-vendor'));
      onNavigate(AppNavigation.VENDOR, vendor.vendor);
    },
    [onAnalytics, onNavigate],
  );

  const _onFindMe = useCallback(() => {
    if (usersEntrantItemRef.current && scrollingContainerRef.current) {
      onAnalytics(trackSelectContent('leaderboard_screen_find-me'));
      setScrollProps({
        reset: true,
        scroll:
          scrollingContainerRef.current.scrollTop +
          usersEntrantItemRef.current.getBoundingClientRect().top -
          (124 + safeAreaTopPadding),
        from: {scroll: scrollingContainerRef.current.scrollTop},
      });
    }
  }, [safeAreaTopPadding, setScrollProps, onAnalytics]);

  const _onUrlPress = useCallback(
    (url: string | undefined) => {
      if (url) {
        onAnalytics(trackSelectContent('latest_screen_url-press'));
        onNavigate(AppNavigation.URL, url);
      }
    },
    [onAnalytics, onNavigate],
  );

  const _onChangeAnswer = useCallback(
    (questionId: string, answer: number) => {
      onAnalytics(trackSelectContent('answers_screen_change-answer'));
      onChangeAnswer(compId, questionId, answer);
    },
    [onAnalytics, onChangeAnswer, compId],
  );

  const _onGetApp = useCallback(() => {
    onAnalytics(trackSelectContent('answers_screen__get-app'));
    onAddOverlay(getNativeAppMissedQuestionOverlay());
  }, [onAnalytics, onAddOverlay]);

  const _onChallengeSelected = useCallback(
    (challenge: TFirebaseChallengeGroup) => {
      onAnalytics(
        trackSelectContent('leaderboards_screen__user-challenge-button'),
      );
      onAddOverlay(getChallengeDetailsOverlay(challenge));
    },
    [onAddOverlay, onAnalytics],
  );

  const _onVipChallengeSelected = useCallback(
    (userA: TFirebaseUser, userB: TFirebaseUser) => {
      onAnalytics(
        trackSelectContent('leaderboards_screen__vip-challenge-button'),
      );
      onAddOverlay(
        getCompareOverlay(
          userA,
          userB,
          vendor.vendor,
          compId,
          questions || undefined,
          competitionData,
        ),
      );
    },
    [vendor, compId, competitionData, questions, onAddOverlay, onAnalytics],
  );

  const _onNewChallengeSelected = useCallback(
    (tracking: string) => () => {
      onAnalytics(trackSelectContent(tracking));
      if (appPlatform === AppPlatform.WEB) {
        onAddOverlay(getNativeAppChallengeOverlay());
      } else {
        onNavigate(AppNavigation.GROUP_CHALLENGE_NEW);
      }
    },
    [onAnalytics, appPlatform, onAddOverlay, onNavigate],
  );

  const _onChangeAnswersCard = useCallback(() => {
    onAnalytics(trackSelectContent('latest_screen__change-answers-card'));
    _onTab(2, false);
  }, [_onTab, onAnalytics]);

  const editableQuestions =
    props.questions?.filter(
      (q) =>
        q.type !== CompQuestionType.TRIVIA &&
        (props.competitionStatus === ClientStatus.OPEN ||
          props.competitionStatus === ClientStatus.PRE_START ||
          (props.competitionStatus === ClientStatus.IN_PLAY &&
            q.status === QuestionStatus.OPEN_CHANGEABLE)),
    ) ?? [];

  const sortedQuestionsForAnswersTab = useMemo(
    () =>
      props.questions
        ? [...props.questions].sort((q1, q2) =>
            props.competitionStatus === ClientStatus.IN_PLAY
              ? q1.status === QuestionStatus.OPEN_CHANGEABLE &&
                q2.status === QuestionStatus.OPEN_CHANGEABLE
                ? 0
                : q1.status === QuestionStatus.OPEN_CHANGEABLE
                  ? -1
                  : q2.status === QuestionStatus.OPEN_CHANGEABLE
                    ? 1
                    : 0
              : 0,
          )
        : [],
    [props.questions, props.competitionStatus],
  );

  return (
    <>
      <IntersectionRoot threshold={threshold}>
        <div className="screen-container__container">
          <animated.div
            className={applyWindowsScrollStyle('screen-container__content')}
            ref={scrollingContainerRef}
            // @ts-ignore JK, scrollTop not working with react-spring types for some reason
            scrollTop={scrollProps.scroll}>
            {isActive && (
              <div className="comp-details__container">
                <div className="comp-details__header-and-tabs">
                  <SimpleHeader
                    style={{
                      paddingBottom: 0,
                      paddingHorizontal: 0,
                    }}
                    safeAreaTopPadding={safeAreaTopPadding}
                    color={props.comp.primaryColor}
                    appPlatform={props.appPlatform}
                    title={props.comp.shortName}
                    liveScore={props.competitionSummary?.liveScore}>
                    {!userHasEntered &&
                      (props.competitionStatus === ClientStatus.OPEN ||
                        props.competitionStatus === ClientStatus.PRE_START ||
                        props.competitionStatus === ClientStatus.IN_PLAY) && (
                        <PrimaryButton
                          label="Play Now"
                          style={{marginHorizontal: 48, width: '80%'}}
                          onPress={_onGoToCompetitionScreen}
                        />
                      )}
                    <Tabs
                      data={tabsList}
                      selectedIndex={
                        subview === CompetitionDetailsSubView.CHAT
                          ? 0
                          : subview === CompetitionDetailsSubView.LATEST
                            ? 1
                            : subview === CompetitionDetailsSubView.ANSWERS
                              ? tabsList.length - 1
                              : 1
                      }
                      color={COLOR_YELLOW}
                      hideBadgeLabels={true}
                      onTabSelected={_onTab}
                    />
                  </SimpleHeader>
                </div>
                {subview === CompetitionDetailsSubView.ANSWERS &&
                  props.competitionStatus !== ClientStatus.OPEN &&
                  props.competitionStatus !== ClientStatus.PRE_START &&
                  userHasEntered &&
                  currentUserId &&
                  props.currentUserLeaderboardEntry && (
                    <UsersEntrantItem
                      className="comp-details__users-entrant-item"
                      player={props.currentUserLeaderboardEntry}
                      playerPosition={_currentUsersLeaderboardIndex + 1}
                      playerCount={props.leaderboard.length}
                      currentUser={props.currentUser}
                      compStatus={props.competitionStatus}
                      usersResults={props.usersCompetitionResults}
                      useFindMeState={false}
                      onFindMe={_onFindMe}
                      onEditProfile={_onEditProfile}
                    />
                  )}
                <div className="comp-details__content-container">
                  {subview === CompetitionDetailsSubView.CHAT ? (
                    props.isCompetitionChatEnabled ? (
                      <>
                        {competitionChat.length > 0 &&
                          competitionChat[0].summary && (
                            <div className="comp-details__editable-questions-container">
                              <SummaryCard
                                summary={competitionChat[0].summary}
                                backgroundColor={
                                  colorConfig.leaderboardItemsBackground
                                }
                                imageUrl={`${props.cdnUrl}/vendors/mixnbot.png`}
                              />
                            </div>
                          )}
                        {currentUserEntry !== null &&
                          questions &&
                          !questions.some(
                            (q) =>
                              q.status === QuestionStatus.INPLAY ||
                              q.status === QuestionStatus.RESOLVED,
                          ) && (
                            <div className="comp-details__editable-questions-container">
                              <ChallengeCard
                                // imageUrl={`${props.cdnUrl}/vendors/_default-enter-comp-challenge-promo.png`}
                                onPress={_onNewChallengeSelected(
                                  'latest_screen__new-challenge-button',
                                )}
                              />
                            </div>
                          )}
                        {editableQuestions.length > 0 &&
                          currentUserEntry !== null && (
                            <div className="comp-details__editable-questions-container">
                              <EditAnswersCard
                                live={
                                  props.competitionStatus ===
                                  ClientStatus.IN_PLAY
                                }
                                entry={currentUserEntry}
                                changeableQuestions={editableQuestions}
                                onPress={_onChangeAnswersCard}
                              />
                            </div>
                          )}
                        <Chat
                          context="comp"
                          invalidationKey={`${
                            props.currentUserEntry?.updated ??
                            props.currentUserEntry?.created ??
                            ''
                          }`}
                          userId={currentUserId ?? ''}
                          messages={competitionChat}
                          vendorById={
                            competitionSummary
                              ? new Map<string, IVendorDetails>().set(
                                  competitionSummary.vendor,
                                  createCompetitionVendor(competitionSummary),
                                )
                              : new Map<string, IVendorDetails>()
                          }
                          cdnUrl={props.cdnUrl}
                          getOpenGraph={_getOpenGraphCallback}
                          getHostname={_getHostname}
                          getComp={() =>
                            competitionSummary
                              ? assertType<ICompSummaryAndEntry>({
                                  summary: competitionSummary,
                                  vendor: vendor,
                                  entry: currentUserEntry,
                                  outstandingQuestions: 0,
                                })
                              : undefined
                          }
                          getQuestion={(questionId: string) =>
                            questions?.find((q) => q.id === questionId)
                          }
                          getLeaderboardSummaryProps={() =>
                            assertType<ILeaderboardSummaryProps>({
                              maxLeaderboardLength: 3,
                              style: {width: '100%'},
                              title: props.leaderboardConfig.shortName,
                              subTitle: `${props.comp.roundName} ∙ ${props.comp.name}`,
                              primaryColor: props.comp.primaryColor,
                              status: props.competitionStatus,
                              players: props.leaderboard,
                              vendorImageUrl: props.comp.logo.image?.url,
                              currentPlayer: props.currentUserLeaderboardEntry,
                              currentUser: props.currentUser,
                              currentUserEntry: props.currentUserEntry,
                              entryStatus: props.entryStatus,
                              entriesCount: props.competitionData.entriesCount,
                              compQuestions: props.questions,
                              vendor: props.comp.vendor,
                              starts: props.comp.starts,
                              followingMap: props.userFollowing,
                              MediaComponent: MediaView as ComponentType<{
                                media: TMediaEntry;
                              }>,
                              getUserById: _getUserByIdCallback,
                              onUserSelected: _onUserSelected,
                              onGoToGame: _onPlayNewQuestionSelected,
                              onGoToLeaderboard: () => _onTab(1, false),
                            })
                          }
                          onGoToGame={_onPlayNewQuestionSelected}
                          onGoToLeaderboard={props.onGoToLeaderboard}
                          onUrlPress={_onUrlPress}
                        />
                      </>
                    ) : (
                      <UpdateRequired onPress={_onUpdateRequiredPressed} />
                    )
                  ) : subview === CompetitionDetailsSubView.ANSWERS ? (
                    <PlayersAnswers
                      appPlatform={props.appPlatform}
                      compStatus={props.competitionStatus}
                      compData={props.competitionData}
                      questions={sortedQuestionsForAnswersTab}
                      userAnswers={currentUserEntry?.answers ?? {}}
                      userImageUrl={props.currentUserImageUrl}
                      followedUsersEntries={props.followedUsersEntries}
                      onPlayComp={_onPlayNewQuestionSelected}
                      onChangeAnswer={_onChangeAnswer}
                      onGetApp={_onGetApp}
                    />
                  ) : subview === CompetitionDetailsSubView.LATEST ? (
                    <Latest
                      challengesEnabled={props.isChallengesEnabled}
                      lastVerifiedCompId={
                        props.competitionStatus === ClientStatus.VERIFIED
                          ? props.comp.id
                          : ''
                      }
                      vendor={vendor}
                      compSummary={props.competitionSummary!}
                      status={props.competitionStatus}
                      players={props.leaderboard}
                      vips={props.vipsInLeaderboard}
                      currentPlayer={props.currentUserLeaderboardEntry}
                      compQuestions={props.questions}
                      currentUser={props.currentUser}
                      currentUserEntry={props.currentUserEntry}
                      entriesCount={props.competitionData.entriesCount}
                      entryStatus={props.entryStatus}
                      leaderboardConfig={props.leaderboardConfig}
                      followingMap={props.userFollowing}
                      challengesWithUnreads={challengesWithUnreads}
                      getUserById={_getUserByIdCallback}
                      getMultiGameLeaderboardSummaryById={
                        _getLeaderboardSummaryAndLeadersByIdCallback
                      }
                      onUserSelected={_onUserSelected}
                      onGoToLeaderboard={props.onGoToLeaderboard}
                      onGoToMultiGameLeaderboard={
                        _onMultiGameLeaderboardSelected
                      }
                      onGoToGame={_onGoToCompetitionScreen}
                      onVendorSelected={_onVendorSelected}
                      onChallengeSelected={_onChallengeSelected}
                      onVipChallengeSelected={_onVipChallengeSelected}
                      onNewChallengeSelected={_onNewChallengeSelected(
                        'leaderboards_screen__new-challenge-button',
                      )}
                    />
                  ) : (
                    <PrizeCard
                      comp={props.comp}
                      leaderboardConfig={props.leaderboardConfig}
                      userHasEntered={userHasEntered}
                      MediaComponent={
                        MediaView as ComponentType<{media: TMediaEntry}>
                      }
                      onGoToLeaderboard={props.onGoToLeaderboard}
                      onMoreInfo={_onMoreInfoSelected}
                    />
                  )}
                  {props.safeAreaBottomPadding > 0 && (
                    <div
                      style={{
                        width: '100%',
                        height: `${props.safeAreaBottomPadding}px`,
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </animated.div>
          {subview === CompetitionDetailsSubView.MORE_INFO &&
            selectedMoreInfo !== null && (
              <LeaderboardDetails
                className="comp-details__overlay"
                safeAreaTopPadding={safeAreaTopPadding}
                details={selectedMoreInfo}
                onClose={() => history(-1)}
              />
            )}
        </div>
      </IntersectionRoot>
    </>
  );
};

const threshold = [0, 0.8];
