import React, {ComponentType, useCallback, useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Observable} from 'rxjs';

import {ClientStatus} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {EnterCompStatus} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseCompEntry,
  TFirebaseQuestion,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  CompQuestionType,
  QuestionStatus,
  TLeader,
  TLeaderboardPrize,
  TMediaEntry,
  TTimestamp,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {formatNumber} from '@chancer/common/lib/utils/NumberUtils';
import {QuestionAnswerState} from '../Answers/QuestionAnswer';
import {getAnswerState, getQuestionState} from '../Answers/QuestionItem';
import {PrimaryButton} from '../Button/PrimaryButton';
import {
  COLOR_BACKGROUND,
  COLOR_DARK,
  COLOR_GREY_6,
  FONT_COPY_MEDIUM,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {EntrantItem, EntryBreakdown} from './EntrantItem';
import {SummaryHeader} from './SummaryHeader';

interface IProps {
  style?: CompositedViewStyle;
  visible?: boolean;
  prizes?: TLeaderboardPrize[];
  title: string;
  subTitle: string;
  primaryColor: string;
  players: TLeader[];
  currentPlayer: TLeader | null;
  currentUser: TFirebaseUser | null;
  currentUserEntry: TFirebaseCompEntry | null;
  entryStatus: EnterCompStatus;
  entriesCount: number;
  starts: TTimestamp;
  status: ClientStatus | null;
  vendor: string;
  vendorImageUrl?: string;
  compQuestions: TFirebaseQuestion[] | null;
  followingMap: {[key: string]: boolean};
  leadersIfOverMax?: number;
  maxLeaderboardLength?: number;
  MediaComponent: ComponentType<{media: TMediaEntry}>;
  isLive?: boolean;
  getUserById: (userId: string) => Observable<TFirebaseUser>;
  onUserSelected: (user: TFirebaseUser) => void;
  onGoToLeaderboard: () => void;
  onGoToGame: () => void;
}

export interface ILeaderboardSummaryProps extends IProps {}

interface ILeaderWithIndex {
  index: number;
  leader: TLeader;
}

export const LeaderboardSummary: React.FC<IProps> = (props) => {
  const {
    visible = true,
    primaryColor,
    players,
    prizes,
    currentPlayer,
    currentUser,
    currentUserEntry,
    entryStatus,
    entriesCount,
    starts,
    status,
    vendor,
    compQuestions,
    leadersIfOverMax,
    maxLeaderboardLength = 5,
    onGoToLeaderboard,
    onGoToGame,
  } = props;

  const usersHaveScores = useMemo(() => {
    return players.some((p) => p.s > 0);
  }, [players]);

  const colorConfig = getColorConfiguration(primaryColor);

  const isOpen =
    status === ClientStatus.OPEN || status === ClientStatus.PRE_START;

  const questionsInPlay = useMemo(() => {
    if (compQuestions === null) {
      return false;
    }

    return compQuestions.some((q) => q.status === QuestionStatus.INPLAY);
  }, [compQuestions]);

  const canEnterComp =
    status === ClientStatus.OPEN ||
    status === ClientStatus.PRE_START ||
    status === ClientStatus.IN_PLAY;

  const {sortedPlayers, currentPlayerWithIndex, showLeaderboardButton} =
    useMemo(() => {
      const playersWithIndex: ILeaderWithIndex[] = players.map(
        (leader, index) => ({leader, index}),
      );

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const currentPlayerWithIndex = playersWithIndex.find(
        (p) => currentUser?.id === p.leader.u,
      );

      // Based on current designs INGAME leaderboards show the latest 5 players
      // and other types show the top 3. Originally, a leaderboard summary was
      // intended to be supplied with the correct number of leaders in the correct
      // order to avoid making assumptions about where the data came from,
      // but it doesn't seem important to enforce that at the moment.
      const copy = [...playersWithIndex];

      // Open leaderboards have the most recent players last.
      if (isOpen && !usersHaveScores) {
        copy.reverse();
      }
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const showLeaderboardButton =
        copy.length > maxLeaderboardLength ||
        (canEnterComp && currentUserEntry === null);

      const sliceLength =
        copy.length > maxLeaderboardLength
          ? leadersIfOverMax ?? maxLeaderboardLength
          : maxLeaderboardLength;

      return {
        sortedPlayers: copy.slice(0, sliceLength),
        currentPlayerWithIndex,
        showLeaderboardButton,
      };
    }, [
      isOpen,
      usersHaveScores,
      players,
      maxLeaderboardLength,
      canEnterComp,
      leadersIfOverMax,
      currentUser,
      currentUserEntry,
    ]);

  const entryBreakdown: EntryBreakdown | undefined = useMemo(() => {
    if (isOpen || props.currentUserEntry === null || compQuestions === null) {
      return undefined;
    }

    return _getEntryBreakdown(compQuestions, props.currentUserEntry, isOpen);
  }, [compQuestions, props.currentUserEntry, isOpen]);

  const pointsBehind = useMemo(() => {
    if (sortedPlayers.length > 0 && currentPlayer !== null) {
      return sortedPlayers[0].leader.s - currentPlayer?.s;
    }

    return 0;
  }, [sortedPlayers, currentPlayer]);

  const _onPress = useCallback(() => {
    currentPlayer !== null ||
    entryStatus === EnterCompStatus.ERROR ||
    entryStatus === EnterCompStatus.PROCESSING ||
    !canEnterComp
      ? onGoToLeaderboard()
      : onGoToGame();
  }, [currentPlayer, entryStatus, canEnterComp, onGoToLeaderboard, onGoToGame]);

  return (
    visible && (
      <View style={[styles.container, props.style]}>
        <SummaryHeader
          vendorImageUrl={props.vendorImageUrl}
          backgroundColor={colorConfig.leaderboardSummaryHeaderBackground}
          title={props.title}
          subTitle={props.subTitle}
          players={props.entriesCount}
          status={{
            startTime: starts,
            status: status ?? ClientStatus.OPEN,
            questionsInPlay: questionsInPlay,
          }}
          media={undefined}
          prizes={prizes}
          MediaComponent={props.MediaComponent}
          isLive={props.isLive}
          onPress={onGoToLeaderboard}
        />
        <View style={styles.contentContainer}>
          {sortedPlayers.map((playerWithIndex) => {
            const player = playerWithIndex.leader;
            const isCurrentUser = currentUser?.id === player.u;
            return (
              <View
                key={player.u}
                style={
                  isCurrentUser
                    ? isOpen
                      ? styles.currentUserLatestLeaderContainer
                      : styles.currentUserLeaderContainer
                    : styles.leaderContainer
                }>
                <EntrantItem
                  position={playerWithIndex.index + 1}
                  colorConfig={colorConfig}
                  visible={visible}
                  isCurrentUser={isCurrentUser}
                  player={player}
                  user={isCurrentUser ? currentUser : null}
                  compVendor={vendor}
                  compStatus={status}
                  showScoreInPrelive={usersHaveScores}
                  winner={player.w === true && status === ClientStatus.VERIFIED}
                  isFollowing={props.followingMap[player.u] === true}
                  entryBreakdown={isCurrentUser ? entryBreakdown : undefined}
                  isSummary={true}
                  getUserById={props.getUserById}
                  onPress={props.onUserSelected}
                />
              </View>
            );
          })}

          {!sortedPlayers.find((p) => currentUser?.id === p.leader.u) &&
            currentPlayerWithIndex &&
            currentPlayer !== null &&
            !isOpen && (
              <View style={styles.currentUserContainer}>
                <Text
                  style={styles.currentUserCopy}
                  numberOfLines={1}
                  ellipsizeMode="tail">
                  You
                  {status === ClientStatus.VERIFIED ? ' finished ' : ' are '}
                  {pointsBehind === 1 ? '1 point ' : `${pointsBehind} points `}
                  behind the leader.
                </Text>
                <View style={styles.currentUserLeaderContainer}>
                  <EntrantItem
                    key={currentUser?.id}
                    position={currentPlayerWithIndex.index + 1}
                    colorConfig={colorConfig}
                    visible={visible}
                    isCurrentUser={true}
                    player={currentPlayer}
                    user={props.currentUser}
                    compVendor={vendor}
                    compStatus={status}
                    showScoreInPrelive={usersHaveScores}
                    winner={false}
                    isFollowing={false}
                    achievements={currentUser?.achievements}
                    entryBreakdown={entryBreakdown}
                    isSummary={true}
                    getUserById={props.getUserById}
                    onPress={props.onUserSelected}
                  />
                </View>
              </View>
            )}
          <View
            style={[
              styles.footerContainer,
              {backgroundColor: colorConfig.leaderboardFooterBackground},
            ]}>
            {showLeaderboardButton && (
              <PrimaryButton
                style={styles.actionButton}
                label={
                  currentPlayer !== null ||
                  props.entryStatus === EnterCompStatus.ERROR ||
                  props.entryStatus === EnterCompStatus.PROCESSING ||
                  !canEnterComp
                    ? entriesCount > sortedPlayers.length
                      ? `Show all ${formatNumber(entriesCount)} fans`
                      : 'Show Leaderboard'
                    : 'Play now'
                }
                onPress={_onPress}
              />
            )}
          </View>
        </View>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {},
  contentContainer: {
    backgroundColor: COLOR_BACKGROUND,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    overflow: 'hidden',
  },
  leaderContainer: {},
  currentUserLeaderContainer: {},
  currentUserLatestLeaderContainer: {
    backgroundColor: COLOR_DARK,
  },
  currentUserContainer: {
    backgroundColor: COLOR_DARK,
  },
  currentUserCopy: {
    marginLeft: 12,
    marginRight: 40,
    color: COLOR_GREY_6,
    fontFamily: FONT_COPY_MEDIUM,
    fontSize: 14,
    marginVertical: 8,
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 2,
    padding: 16,
    minHeight: 64,
  },
  actionButton: {
    width: '100%',
  },
});

const _getEntryBreakdown = (
  compQuestions: TFirebaseQuestion[],
  currentUserEntry: TFirebaseCompEntry,
  isCompetitionOpen: Boolean,
) => {
  const entryBreakdown: EntryBreakdown = {
    [QuestionAnswerState.CORRECT]: 0,
    [QuestionAnswerState.WRONG]: 0,
    [QuestionAnswerState.OPEN]: 0,
    [QuestionAnswerState.CHANGEABLE]: 0,
    [QuestionAnswerState.IN_PLAY]: 0,
  };
  compQuestions.forEach((question) => {
    const result = question.resolved ?? undefined;
    entryBreakdown[
      getAnswerState(
        question.status,
        getQuestionState(
          question.status,
          isCompetitionOpen,
          false,
          result,
          true,
          currentUserEntry.answers[question.id],
          question.type as CompQuestionType,
        ),
        question.type,
        result,
        currentUserEntry.answers[question.id],
      )
    ]++;
  });

  return entryBreakdown;
};
