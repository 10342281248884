import React from 'react';

import {ClientStatus} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseCompCounts,
  TFirebaseQuestion,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  CompQuestionType,
  TCompEntryAnswers,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {getAnswersBreakdownForQuestion} from '@chancer/common/lib/utils/CompetitionUtils';
import {applyPropClassName} from '@chancer/common/lib/utils/ComponentUtils';
import {QuestionItem} from '@chancer/components/lib/Answers/QuestionItem';
import {getResolutionForQuestion} from '@chancer/components/lib/Answers/QuestionUtils';
import './PlayersAnswers.scss';

interface IProps {
  className?: string;
  appPlatform: AppPlatform;
  compStatus: ClientStatus;
  compData: TFirebaseCompCounts;
  questions: TFirebaseQuestion[];
  userAnswers: TCompEntryAnswers;
  userImageUrl: string | null;
  followedUsersEntries: {[questionId: string]: TFirebaseUser[][]};
  onPlayComp: () => void;
  onChangeAnswer: (questionId: string, answer: number) => void;
  onGetApp: () => void;
}

export const PlayersAnswers: React.FC<IProps> = (props) => {
  const _createList = (
    appPlatform: AppPlatform,
    questions: TFirebaseQuestion[],
    compData: TFirebaseCompCounts,
    compStatus: ClientStatus,
    userAnswers: TCompEntryAnswers,
    userImageUrl: string | null,
    onPlayComp: () => void,
    onChangeAnswer: (questionId: string, answer: number) => void,
    onGetApp: () => void,
  ) =>
    questions.map((question) => {
      return (
        <QuestionItem
          key={`question${question.id}`}
          appPlatform={appPlatform}
          index={question.index}
          question={question.question}
          hint={question.hint ?? undefined}
          answers={question.answers}
          isCurrentUser={true}
          answersBreakdown={getAnswersBreakdownForQuestion(
            question.id,
            compData,
          )}
          status={question.status}
          isCompetitionOpen={
            compStatus === ClientStatus.OPEN ||
            compStatus === ClientStatus.PRE_START
          }
          result={getResolutionForQuestion(
            question.id,
            compData.resolvedQuestions,
            compData.resolutions ?? [],
          )}
          type={question.type as CompQuestionType}
          userAnswer={userAnswers[question.id]}
          userImageUrl={userImageUrl}
          followedUserEntries={props.followedUsersEntries[question.id] ?? []}
          starts={question.starts?.toDate() ?? undefined}
          onPlayComp={onPlayComp}
          onChangeAnswer={(answer: number) =>
            onChangeAnswer(question.id, answer)
          }
          onGetApp={onGetApp}
        />
      );
    });

  return (
    <div className={applyPropClassName('players-answers__container', props)}>
      {(props.compStatus === ClientStatus.OPEN ||
        props.compStatus === ClientStatus.PRE_START) && (
        <div className="players-answers__spacer" />
      )}
      {_createList(
        props.appPlatform,
        props.questions,
        props.compData,
        props.compStatus,
        props.userAnswers,
        props.userImageUrl,
        props.onPlayComp,
        props.onChangeAnswer,
        props.onGetApp,
      )}
    </div>
  );
};
